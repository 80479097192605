import React from 'react'
import Layout from '../components/layout-v2';


const Page = () => (

  <Layout title="News & Press">
    <section className="section-news">

      <div className="border">
        <div>Indore Patrika - 05/05/2015</div>
        <a href="http://epaper.patrika.com/c/5177895" target="_blank" rel="noopener noreferrer" >
          http://epaper.patrika.com/c/5177895
        </a>
        <br/>
        <a href="http://epaper.patrika.com/493197/Indore-Patrika/05-05-2015#page/13/2" target="_blank" rel="noopener noreferrer" >
          http://epaper.patrika.com/493197/Indore-Patrika/05-05-2015#page/13/2
        </a>
        
        <a href="http://epaper.patrika.com/c/5177895" target="_blank" rel="noopener noreferrer" >
          <div className="patrika-parakh-education-portal img-responsive"></div>
        </a>
      </div>
      <br/>
      <div className="border">
        <div>Khargone Patrika - 06/05/2015</div>
        <a href="http://epaper.patrika.com/c/5188284" target="_blank" rel="noopener noreferrer" >
          http://epaper.patrika.com/c/5188284
        </a>
        <br/>
        <a href="http://epaper.patrika.com/493683/Khargon-patrika/06-05-2015#page/1/1" target="_blank" rel="noopener noreferrer" >
          http://epaper.patrika.com/493683/Khargon-patrika/06-05-2015#page/1/1
        </a>
        
        <a href="http://epaper.patrika.com/c/5188284" target="_blank" rel="noopener noreferrer" >
          <div className="patrika-khg-06-parakh-education-portal img-responsive"></div>
        </a>
      </div>


    </section>
  </Layout>
);

export default Page;


/*
      <!-- title="Patrika Parakh Education Portal" alt-->
      <!-- http://epaper.patrika.com/r/493197/13 -->
      <!-- http://epaper.patrika.com/r/493683/1 -->
 */
