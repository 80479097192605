import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import '../styles/style.scss';
import Header from './header'
import Footer from './footer';

const Layout = ({children, title}) => (
  <>
    <Helmet
      title={title}
      meta={[
        {name: 'description', content: title},
        {name: 'keywords', content: title},
      ]}
    >
      <html lang="en"/>
      <script type="text/javascript" src="//s7.addthis.com/js/300/addthis_widget.js#pubid=ra-5d09cfd5b41bad4c" async="async"></script>
    </Helmet>

    <section className="section-main">
      <Header/>

      <section className="section-layout-v2 ">

        <div className="homepage-landing-page-section homepage-landing-page-section">

          <div className="container">
            <div className="row hero-section">

              <div className="container">

                <div className="large-shadow-text">

                </div>

              </div>

            </div>
          </div>

          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1442.484 241.391" className="arch">
            <path fill="#fff"
                  d="M0 241.375s31.86-62.023 155.407-118.648 315.731-66.921 442.71-44.614 245.378 61.774 401.528 70.353S1347.87 139.218 1442.484.001c-.031 4.109-.031 110.39-.031 115.562 0 .875.014 116.719.031 125.828-178.221.011-1184.957-.526-1442.484-.016z"
                  data-name="Path 1"></path>
          </svg>

        </div>

      </section>
      <div className="container">
        <h1 className="line-main">{title}</h1>
        {children}
      </div>


      <Footer/>
    </section>
  </>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout
